.App {
  text-align: center;
  position: relative;
  overflow: hidden;
}

.react-multiple-carousel__arrow {
  background: transparent;
  min-width: auto;
}

.react-parallax  {
  overflow: visible !important;
}
.react-parallax-background-children {
  position: relative !important;
  left: 0 !important;
}